@import '../../assets/helpers/index.scss';

.class {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 50px;

  @media screen and (max-width: 600px) {
    height: auto;
    justify-content: center;
  }

  &__name {
    font-size: 25px;
    color: $mainColor;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80px;

    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
  }

  &__menu {
    font-size: 12px;
    color: $secondaryColor;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -7px;
  }
}

.triangle {
  margin-top: 17%;
  margin-left: 3px;
  border: 4px solid transparent;
  border-top: 5px solid $secondaryColor;
}

@keyframes slidein {
  0% {
    left: 0%;
    opacity: 0;

    @media screen and (max-width: 600px) {
      right: 0%;
    }
  }

  100% {
    left: 150%;
    opacity: 1;
    @media screen and (max-width: 600px) {
      right: 120%;
    }
  }
}

@keyframes slideout {
  0% {
    left: 150%;
    opacity: 1;
    @media screen and (max-width: 600px) {
      right: 120%;
    }
  }

  100% {
    left: 100%;
    opacity: 0;
    @media screen and (max-width: 600px) {
      right: 100%;
    }
  }
}

@keyframes slideinMobile {
  0% {
    right: 0%;
    opacity: 0;
  }

  100% {
    right: 120%;
    opacity: 1;
  }
}

@keyframes slideoutMobile {
  0% {
    right: 120%;
    opacity: 1;
  }

  100% {
    right: 100%;
    opacity: 0;
  }
}

.modal {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: #fff;
  left: 150%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  z-index: -1;

  @media screen and (max-width: 600px) {
    right: 120%;
    left: auto;
  }

  &_opened {
    animation-duration: 0.3s;
    animation-name: slidein;
    z-index: 1;

    @media screen and (max-width: 600px) {
      animation-name: slideinMobile;
    }
  }

  &_closed {
    opacity: 0;
    animation-duration: 0.1s;
    animation-name: slideout;
    left: -200px;

    @media screen and (max-width: 600px) {
      animation-name: slideoutMobile;
    }
  }

  &__item {
    padding: 0px 15px;
    font-size: 16px;
    font-weight: bold;
    color: $secondaryColor;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: $mainColor;
    }
  }
}
