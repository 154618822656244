@import '../../../assets/helpers/index.scss';

.calendar {
  display: flex;
  flex-direction: column;
  width: fit-content;

  &__header {
    display: flex;
    align-items: center;
    margin: 0 auto 10px;

    & span {
      font-size: 20px;
      font-weight: bold;
      width: 100px;
      text-align: center;
    }
  }

  &__nextMonth,
  &__lastMonth {
    padding: 10px 10px;
    transform: rotate(-90deg);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: $mainColor;
    }
  }

  &__lastMonth {
    transform: rotate(90deg);
  }

  &__weekdays {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $backgroundColor;

    & > span {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;

      &:nth-child(6),
      &:nth-child(7) {
        color: $secondaryColor;
      }
    }
  }

  &__days {
    display: flex;
    flex-wrap: wrap;
    width: 211px;
    border-left: 1px solid #fff;
  }

  &__day {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    font-weight: bold;
    background-color: $backgroundColor;
    cursor: pointer;
    border-radius: 5px;

    &_otherMonth,
    &_weekend {
      color: $secondaryColor;
    }

    &_otherMonth {
      opacity: 0.5;
    }

    &_today {
      // border: 1px solid $mainColor;
      color: $mainColor;
    }

    &:hover {
      background-color: $mainColor;
      color: #fff;
    }

    &_active {
      background-color: $mainColor;
      color: #fff;
      border-color: $mainColor;
      border-radius: 0px;
    }
  }

  &__date {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &_inactive {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      color: $secondaryColor;
    }
  }
}
