@import '../../../assets/helpers/index.scss';

.layout {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.header{
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  flex-shrink: 0;

  &__sidebar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: $sidebarWidth - 40px;
  }
}

.main{
  display: flex;
}

.sidebar{
  flex-shrink: 0;
  width: $sidebarWidth;
  z-index: 2;
  overflow-x: hidden;
  transition: 0.3s;
  min-height: calc(100vh - 90px);

  &_hidden{
    width: 0;
  }
}

.content{
  background-color: $backgroundColor;
  width: 100%;
  min-height: 100%;
  padding: 40px;
  margin: 0px 20px;
  border-radius: 20px 20px 0 0;
}
