@import '../../../assets/helpers/index.scss';

.form {
  display: flex;
  flex-direction: column;
  z-index: 1;

  &__class {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  & > *:not(:first-child, :last-child) {
    margin-top: 40px;
    position: relative;
    display: flex;
  }

  &__error {
    margin-top: 20px !important;
    margin-bottom: -20px !important;
    color: $negativeColor;
  }
}
