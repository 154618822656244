@import '../../assets/helpers/index.scss';

.header {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
  }
}
