$mainTextColor: #333333;
$secondaryColor: #7c7c7c;
$backgroundColor: #f1f5f8;
$mainColor: #ff6633;
$mainColorHover: rgba(255, 102, 51, 0.2);
$disableColor: rgba(124, 124, 124, 0.2);
$negativeColor: #e33f3d;
$warningColor: #ffc123;
$positiveColor: #24b26d;
$infoColor: #66d4fc;
$popupBgColor: rgba(0, 0, 0, 0.8);
