@import '../../assets/helpers/index.scss';

.members {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $backgroundColor;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 20px;

  &__sum {
    width: 1080px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid $disableColor;
    padding: 10px 20px;
    gap: 20px;

    @media screen and (max-width: 1100px) {
      width: 980px;
    }

    @media screen and (max-width: 1000px) {
      width: 830px;
    }
  }

  &__sumHeader {
    margin-right: 5px;
  }

  &__sumItem {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: $secondaryColor;
    padding: 5px 10px;
    background-color: $backgroundColor;
    border: 1px solid $disableColor;

    &_positive {
      border-color: $positiveColor;
    }

    &_negative {
      border-color: $negativeColor;
    }
  }

  &__table {
    display: flex;
    flex-shrink: 0;
    gap: 20px;
    max-width: 100vw;
    overflow-x: scroll;
    padding: 10px 0px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (max-width: 850px) {
    .members {
      &__table {
        padding: 10px 0px;
      }

      &__sum {
        width: 300px;
        gap: 5px;
        padding: 10px;
      }

      &__sumItem {
        font-size: 12px;
      }
    }
  }
}

.switcher {
  display: flex;
  align-items: center;
  gap: 5px;
  @media screen and (min-width: 851px) {
    display: none;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;
    border: 1px solid $disableColor;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
}

.author {
  margin-top: auto;
  margin-left: auto;
  color: $secondaryColor;
  font-size: 12px;
  text-align: end;
}

.members_print {
  padding: 0px;

  & * {
    color: $mainTextColor !important;
  }

  .author {
    margin: 0 0 0 auto;
  }

  .members__status {
    display: none;
  }

  .members__sum {
    width: 1020px;
    padding: 0px 20px;
    border: none;
    background-color: $backgroundColor;
  }

  .members__sumItem {
    border: none;
  }

  .members__table {
    gap: 5px;
    padding: 3px;
  }

  .switcher {
    display: none;
  }
}
