@import '../../assets/helpers/index.scss';

.bar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 120px;
  background-color: #fff;
  border-radius: 10px;

  @media screen and (max-width: 600px) {
    width: 90px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 90px;

    @media screen and (max-width: 600px) {
      height: 80px;
    }
  }

  &__assistant {
    width: 100%;
    height: 30px;
    border-top: 1px solid $disableColor;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $mainColor;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      text-align: center;
      height: 40px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.activity {
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    display: none;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }

  &__header {
    font-size: 12px;
    font-weight: bold;
  }

  &__subheader {
    font-size: 12px;
    color: $secondaryColor;
    margin-top: 5px;

    &_bad {
      color: $warningColor;
    }

    &_good {
      color: $positiveColor;
    }
  }
}

.progress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
