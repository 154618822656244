@import '../../../assets/helpers/index.scss';

.task {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  background-color: #fff;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;

  &__skeleton {
    width: 100%;
    padding-right: 10px;
  }

  &_disabled {
    opacity: 0.1;
    transition: 0.3s;
  }

  &_late {
    color: $negativeColor;
    border-color: $negativeColor !important;
  }

  &__points {
    width: 20px;
    height: 20px;
    background-color: $positiveColor;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    flex-shrink: 0;

    &_disabled {
      background-color: $disableColor;
    }

    &_warning {
      background-color: $warningColor;
    }

    &_negative {
      background-color: $negativeColor;
    }
  }

  @keyframes slidein {
    0% {
      bottom: 50%;
      opacity: 0;
    }

    100% {
      bottom: 100%;
      opacity: 1;
    }
  }

  @keyframes slideout {
    0% {
      bottom: 100%;
      opacity: 1;
    }

    100% {
      bottom: 50%;
      opacity: 0;
    }
  }

  &__popup {
    bottom: 0%;
    position: absolute;
    opacity: 0;
    z-index: -1;
    animation-duration: 0.2s;
    animation-name: slideout;

    &_active {
      bottom: 100%;
      opacity: 1;
      z-index: 2;
      animation-name: slidein;
    }
  }
}
