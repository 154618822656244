@import '../../../../assets/helpers/index.scss';

.input {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;

  & input {
    height: 30px;
    border: 1px solid $disableColor;
    border-radius: 4px;
    background-color: $backgroundColor;
    padding: 0 35px 0 35px;

    &::placeholder {
      color: $secondaryColor;
    }

    @media screen and (max-width: 1200px) {
      padding-right: 10px;
      padding-left: 25px;
    }
  }

  &__label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  &_invalid input {
    border-color: $negativeColor;
  }

  &__error {
    color: $negativeColor;
    position: absolute;
    top: 55px;
    font-size: 12px;

    &_up {
      top: 30px;
    }
  }

  &__validateIcon {
    position: absolute;
    right: 5px;
    top: 30px;

    &_up {
      top: 8px;
    }
  }

  &__icon {
    position: absolute;
    left: 10px;
    top: 30px;

    &_up {
      top: 9px;
    }
  }
}
