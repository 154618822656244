@import '../../../assets/helpers/index.scss';

.status {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: $secondaryColor;
  padding: 0 0 3px;
  width: 1080px;

  @media screen and (max-width: 1100px) {
    width: 980px;
  }

  @media screen and (max-width: 1000px) {
    width: 830px;
  }

  @media screen and (max-width: 850px) {
    width: 300px;
  }
}

.statusIndicator {
  display: flex;
  align-items: center;
  gap: 5px;
}

@keyframes pulse {
  0% {
    width: 5px;
    height: 5px;
  }
  25% {
    width: 7px;
    height: 7px;
  }
  50% {
    width: 10px;
    height: 10px;
  }
  75% {
    width: 7px;
    height: 7px;
  }
  100% {
    width: 5px;
    height: 5px;
  }
}

.statusIcon {
  width: 14px;
  height: 14px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid $disableColor;
  border-radius: 2px;

  &::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: $positiveColor;
    border-radius: 2px;
  }

  &_negative {
    &::after {
      background-color: $negativeColor;
    }
  }

  &_loading {
    &::after {
      background-color: $warningColor;
      animation-duration: 1s;
      animation-name: pulse;
      animation-iteration-count: infinite;
    }
  }
}
