@import '../../../../../assets/helpers/index.scss';

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 850px) {
      flex-direction: column-reverse;
      gap: 20px;
      align-items: flex-start;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
  }

  &__link {
    color: $secondaryColor;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;

    @media screen and (max-height: 600px) {
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
      padding: 5px;
    }
  }

  &__description {
    max-width: 50%;
    color: $secondaryColor;

    @media screen and (max-width: 850px) {
      max-width: 100%;
    }

    @media screen and (max-height: 730px) {
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
      padding: 5px;
    }
  }

  &__img {
    position: absolute;
    z-index: -1;
    bottom: 0px;
    right: 0px;

    @media screen and (max-height: 850px) {
      opacity: 0.1;
      overflow: hidden;
    }
  }

  &__main {
    margin-top: 40px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
}
