.plan {
  display: flex;
  flex-direction: column;

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__main {
    margin-top: 40px;
  }

  &__study {
    display: flex;
    flex-direction: column;

    & > *:first-child {
      margin-bottom: 20px;
    }
  }
}
