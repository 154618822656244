@import '../../../assets/helpers/index.scss';

.row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__submenu {
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 2px solid $backgroundColor;
    padding: 0px 40px;

    @media screen and (max-width: 600px) {
      padding: 0px 20px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin: 0px auto 0px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media screen and (max-width: 600px) {
      font-size: 14px;
      padding-right: 10px;
    }
  }

  &__tasks {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 20px;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
