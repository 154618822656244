.page {
  overflow-x: hidden;
  position: relative;
  max-width: 100vw;
  max-height: 100vh;
}

.wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
