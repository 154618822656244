@import '../../../../assets/helpers/index.scss';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  padding: 10px 20px;
  background-color: #fff;
  border-bottom: 1px solid $disableColor;

  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.col {
  margin-left: 10px;
  display: flex;
  align-items: center;

  &__button {
    padding-left: 5px;
    min-width: 95px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:not(.col_disabled):hover .col__do {
      text-decoration: underline;
    }
  }

  &__do {
    padding-left: 5px;
    color: $mainTextColor;
  }

  &_disabled &__do {
    color: $secondaryColor;
  }
}
