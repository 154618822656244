@import '../../../assets/helpers/index.scss';

.login {
  display: flex;
  width: 100%;
}

.androidDownload {
  position: absolute;
  width: calc(100vw - 80px);
  padding: 20px 0;
  justify-content: right;
  display: none;
  margin: 0 80px 0 55px;
  gap: 5px;
  align-items: center;

  @media screen and (max-width: 600px) {
    display: flex;
  }

  &__link {
    color: $secondaryColor;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    text-align: left;
    padding: 20px 0;

    @media screen and (max-height: 600px) {
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
      padding: 5px;
    }
  }
}
