.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  &__item {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    position: relative;
  }
}
