@import '../../assets/helpers/colors.scss';

.user {
  display: flex;
  align-items: center;
  min-width: 150px;
  margin-right: 40px;

  @media screen and (max-width: 600px) {
    margin-right: 10px;
  }

  &__activity {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: $backgroundColor;
    color: $mainColor;
    font-weight: bold;
  }

  &__name {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    font-weight: bold;
    color: $secondaryColor;

    & *:first-child {
      font-size: 16px;
      color: $mainTextColor;
    }
  }
}
