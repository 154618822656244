@import '../../../assets/helpers/index.scss';

.menu {
  height: 60px;
  width: 100vw;
  background-color: #fff;
  box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  transition: 0.3s;

  &__items {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__item {
    display: flex;
    margin-left: 25px;
    justify-content: space-between;
  }

  &__classChanger {
    display: flex;
    justify-content: center;
    margin-right: 20px;
  }

  &__user {
    display: flex;
    justify-content: center;
    border-top: 1px solid $disableColor;
    padding: 25px 0;
  }

  &__expander {
    position: absolute;
    left: calc(100% - 15px);
    top: 85px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    line-height: 14px;
    transform: rotate(-90deg);
  }

  &_expanded {
    width: 250px;
  }

  &_expanded &__expander {
    transform: rotate(90deg);
  }
}

.avatar {
  width: 50px;
  height: 50px;
  background-color: #ff6633;
  border-radius: 50%;
}
