@import '../../../assets/helpers/index.scss';

.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.task {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px 0 10px;
  height: 30px;
  background-color: $backgroundColor;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: $secondaryColor;
  white-space: nowrap;
  cursor: pointer;

  &__points {
    width: 20px;
    height: 20px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: $positiveColor;
    color: #fff;
    margin-right: 10px;

    &_disabled {
      background-color: $secondaryColor;
    }

    &_warning {
      background-color: $warningColor;
    }

    &_negative {
      background-color: $negativeColor;
    }
  }

  @keyframes slidein {
    0% {
      bottom: 0%;
      opacity: 0;
    }

    100% {
      bottom: 5%;
      opacity: 1;
    }
  }

  @keyframes slideout {
    0% {
      bottom: 5%;
      opacity: 1;
    }

    100% {
      bottom: 0%;
      opacity: 0;
    }
  }

  &__popup {
    margin-left: 10px;
    bottom: 0%;
    position: absolute;
    opacity: 0;
    z-index: -1;
    animation-duration: 0.2s;
    animation-name: slideout;
    cursor: pointer;

    &_active {
      bottom: 5%;
      opacity: 1;
      z-index: 2;
      animation-name: slidein;
    }
  }
}
