@import '../../../assets/helpers/index.scss';

$colWidth: 200px;

.plat {
  display: flex;
  flex-direction: column;
  width: $colWidth;
  flex-shrink: 0;

  background-color: #fff;
  border: 1px solid $disableColor;
  padding: 10px 0;

  &__submenu {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1px solid $disableColor;
    padding: 0px 20px 10px;
    margin-bottom: 10px;

    span {
      font-size: 16px;
    }
  }

  &__status {
    width: 20px;
    height: 20px;
    border: 1px solid $disableColor;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 4px;
      background-color: $positiveColor;
    }
  }

  &__kid {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 10px;

    max-width: 100%;
    cursor: pointer;

    &_female {
      background-color: rgb(252, 229, 231);
    }

    &_position {
      position: absolute;
      right: 35px;
      top: -2px;
      font-size: 10px;
      padding: 1px 3px;
      background-color: $infoColor;
      border: 1px solid $disableColor;
      border-radius: 2px;
      opacity: 0.7;
      color: $mainTextColor !important;
    }

    & > span {
      width: 85%;
      background-color: $backgroundColor;
      padding: 3px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: 1px solid $disableColor;
      border-radius: 2px;
    }

    &_ill {
      color: $secondaryColor;

      & .plat__status {
        &::after {
          opacity: 0.9;
          background-color: $negativeColor;
        }
      }
    }

    &:hover {
      span {
        background-color: #fff;
        color: $mainTextColor;
      }

      .plat__status {
        &::after {
          opacity: 0.5;
        }
      }
    }
  }

  &__counts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid $disableColor;
    padding: 0 15px 10px;
    margin-bottom: 10px;
  }

  &__row {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    max-width: 30%;

    & > span {
      font-size: 12px;
      color: $secondaryColor;
    }
  }

  &__number {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $backgroundColor;
    border: 1px solid $disableColor;
    border-radius: 2px;
    font-weight: bold;

    &_positive {
      border-color: $positiveColor;
      color: $positiveColor;
    }

    &_negative {
      border-color: $negativeColor;
      color: $negativeColor;
    }
  }
}

@media screen and (max-width: 1100px) {
  .plat {
    width: 180px;

    &__counts {
      padding: 0 10px 10px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .plat {
    width: 150px;

    &__kid {
      font-size: 10px;

      &_position {
        right: 30px;
        top: -2px;
      }
    }

    &__row {
      & > span {
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .plat {
    width: 300px;
    margin: 0px calc((100vw - 300px) / 2);

    &__kid {
      font-size: 14px;
      padding: 3px 20px;

      &_position {
        right: 55px;
        top: -2px;
      }
    }

    &__row {
      & > span {
        font-size: 14px;
      }
    }

    &__counts {
      padding: 0 30px 10px;
    }
  }
}

.plat_print {
  .plat__submenu {
    display: none;
  }

  .plat__kid {
    padding: 0px 10px;

    span {
      border: none;
      background-color: #fff;
    }
  }

  .plat__status {
    opacity: 0.5;

    &::after {
      display: none;
    }
  }

  .plat__number {
    opacity: 0.5;
    border: none;
    background-color: #fff;
  }

  .plat__counts {
    padding: 0 15px 5px;
  }

  .plat__header {
    margin-bottom: 3px;
  }
}
