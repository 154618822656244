@import '../../../assets/helpers/index.scss';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100vw;
  // height: 100vh;
  // background-color: $popupBgColor;
  // z-index: 10;
  width: 100%;
  height: 100%;
}

$offset: 0;
$offset-max: 40px;
$width: $offset-max/2;
$duration: 4s;
$spinning-duration: $duration;
$stagger: $duration/2;
$round: 0.3rem;

// $color1: #ef5350;
// $color2: #29b6f6;
// $color3: #ffca28;
// $color4: #5c6bc0;

$color1: $mainColor;
$color2: $negativeColor;
$color3: $warningColor;
$color4: $positiveColor;

$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);

.loading {
  width: $width;
  height: $width;
  transform: rotate(45deg);
  animation: loading-ani1 $spinning-duration $ease-out-quart infinite;
}

.loading i {
  width: $width;
  height: $width;
  display: inline-block;
  border-radius: $round;
  position: absolute;
}
.loading i:nth-child(1) {
  background: $color1;
  transform: translate(0, $offset);
  animation: loading-ani2 $duration ease-in-out infinite;
}
.loading i:nth-child(2) {
  background: $color2;
  transform: rotate(90deg) translate(0, $offset);
  animation: loading-ani3 $duration + $stagger ease-in-out infinite;
}
.loading i:nth-child(3) {
  background: $color3;
  transform: rotate(180deg) translate(0, $offset);
  animation: loading-ani4 $duration + $stagger + $stagger ease-in-out infinite;
}
.loading i:nth-child(4) {
  background: $color4;
  transform: rotate(270deg) translate(0, $offset);
  animation: loading-ani5 $duration + $stagger + $stagger + $stagger ease-in-out
    infinite;
}

@keyframes loading-ani1 {
  25% {
    transform: rotate(135deg);
  }
  50% {
    transform: rotate(225deg);
  }
  75% {
    transform: rotate(315deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

@keyframes loading-ani2 {
  17.5%,
  25%,
  42.5%,
  50%,
  67.5%,
  75%,
  92.5%,
  100% {
    transform: translate(0, $offset);
  }
  12.5%,
  37.5%,
  62.5%,
  87.5% {
    transform: translate(0, $offset-max);
  }
}

@keyframes loading-ani3 {
  17.5%,
  25%,
  42.5%,
  50%,
  67.5%,
  75%,
  92.5%,
  100% {
    transform: rotate(90deg) translate(0, $offset);
  }
  12.5%,
  37.5%,
  62.5%,
  87.5% {
    transform: rotate(90deg) translate(0, $offset-max);
  }
}

@keyframes loading-ani4 {
  17.5%,
  25%,
  42.5%,
  50%,
  67.5%,
  75%,
  92.5%,
  100% {
    transform: rotate(180deg) translate(0, $offset);
  }
  12.5%,
  37.5%,
  62.5%,
  87.5% {
    transform: rotate(180deg) translate(0, $offset-max);
  }
}

@keyframes loading-ani5 {
  17.5%,
  25%,
  42.5%,
  50%,
  67.5%,
  75%,
  92.5%,
  100% {
    transform: rotate(270deg) translate(0, $offset);
  }
  12.5%,
  37.5%,
  62.5%,
  87.5% {
    transform: rotate(270deg) translate(0, $offset-max);
  }
}
