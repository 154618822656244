@import '../../../assets/helpers/index.scss';

.bar {
  display: flex;
  align-items: center;
  max-width: 100%;

  &__item {
    position: relative;
    padding: 0px 15px;
    height: 30px;
    background-color: #fff;
    border: 1px solid $disableColor;
    border-left: none;
    font-size: 16px;
    font-weight: bold;
    color: $secondaryColor;
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 3px 10px;

    &:first-child {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border: 1px solid $disableColor;
    }

    &:last-child {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }

    &_active {
      background-color: $mainColor;
      color: #fff;
    }
  }
}
