@import '../../../assets/helpers/index.scss';

.points {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  & > span {
    margin-bottom: 20px;
    color: $secondaryColor;
  }

  &__picker {
    display: flex;
    align-items: center;
  }

  &__arrow {
    font-size: 25px;
    font-weight: bold;
    padding: 0 20px;

    &:hover {
      color: $mainColor;
      cursor: pointer;
    }
  }

  &__count {
    font-size: 20px;
    font-weight: bold;
    width: 125px;
    text-align: center;
    display: flex;
    align-items: center;

    & div {
      min-width: 30px;
      min-height: 30px;
      border: 1px solid $positiveColor;
      border-radius: 5px;
      background-color: $backgroundColor;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;
      margin-right: 7px;
      font-size: 16px;
      color: $positiveColor;
    }

    &_warning {
      div {
        color: $mainTextColor;
        border-color: $warningColor;
      }
    }

    &_negative {
      div {
        color: $negativeColor;
        border-color: $negativeColor;
      }
    }
  }
}
