.menu {
  display: flex;
  gap: 20px;
  margin-left: 20px;

  @media screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
}
