@import '../../../../assets/helpers/index.scss';

.mark {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $disableColor;
  background-color: $backgroundColor;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  font-weight: bold;
  color: $secondaryColor;
  cursor: default;

  &_5 {
    color: $positiveColor;
    border-color: $positiveColor;
  }

  &_4 {
    color: #1cb5f1;
    border-color: #1cb5f1;
  }

  &_3 {
    color: $warningColor;
    border-color: $warningColor;
  }

  &_2 {
    color: $negativeColor;
    border-color: $negativeColor;
  }

  &_new {
    box-shadow: 0px 0px 15px $infoColor;
  }
}

.date {
  position: absolute;
  top: -16px;
  font-size: 12px;
  font-weight: 400;
  color: $secondaryColor;
}
