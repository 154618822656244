@import '../../../assets/helpers/index.scss';

.steps {
  display: flex;
  width: 100%;
}

.sidebar {
  @media screen and (max-width: 600px) {
    width: 1px;
    max-height: 100vh;
    overflow: hidden;
  }
}
