.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 3;
  overflow-y: scroll;

  &__overlay {
    position: fixed;
    background-color: hsla(0, 0%, 0%, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  &__content {
    padding: 30px;
    z-index: 3;
    margin: auto;
  }
}
