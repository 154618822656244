@import '../../../assets/helpers/index.scss';

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  &__row {
    display: flex;
    align-items: center;
    min-height: 40px;
    border-bottom: 1px solid $disableColor;
    background-color: #fff;
    padding: 0px 20px;
  }

  &__header {
    font-size: 16px;
    font-weight: bold;
  }

  &__empty {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 50px 140px 50px 20px;

    &_header {
      font-size: 20px;
      font-weight: bold;
      z-index: 1;
      background-color: #fff;
    }

    &_descr {
      color: $secondaryColor;
      font-size: 16px;
      margin-top: 10px;
      z-index: 1;
      background-color: #fff;
    }

    img {
      position: absolute;
      z-index: 0;
      right: 40px;
    }
  }
}
