@import '../../../assets/helpers/index.scss';

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  border-bottom: 2px solid $backgroundColor;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  gap: 20px;

  @media screen and (max-width: 600px) {
    gap: 10px;
    padding: 15px;
  }
}

.name {
  font-size: 16px;
  font-weight: bold;
}

.summary {
  display: flex;

  &_positive,
  &_negative {
    display: flex;
    gap: 20px;
  }

  &__header {
    display: flex;
    height: 100%;
    align-items: center;
    padding-right: 20px;
    border-right: 2px solid $disableColor;
    font-weight: bold;
    font-size: 16px;
    color: $secondaryColor;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__items {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 600px) {
      gap: 10px;
    }

    &:last-child {
      margin-left: 38px;
    }
  }
}

.task {
  display: flex;
  align-items: center;

  &__label {
    &_checked {
      color: $secondaryColor;
      text-decoration: line-through;
    }
  }

  &__createdAt {
    margin-left: 10px;
    color: $secondaryColor;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}

.checked {
  color: $positiveColor !important; // зеленый цвет
}
