@import '../../../../assets/helpers/index.scss';

.item {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    width: 40px;
    height: 40px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.1s;

    &:hover:not(.item_active) {
      transform: translateX(3px);
    }
  }

  &__label {
    margin-left: 10px;
    font-size: 16px;
    width: 0px;
    overflow: hidden;
    transition: 0.3s;

    &_expanded {
      width: 150px;
    }
  }

  &_active {
    background-color: $mainColor;
  }

  &_disabled {
    cursor: default;
    svg {
      path {
        fill: $disableColor;
      }
    }
  }
}
