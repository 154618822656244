@import '../../../assets/helpers/index.scss';
$rowHeight: 20px;

.plat {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  width: 185px;
  border: 1px solid #000;
  height: fit-content;

  &__header {
    height: $rowHeight;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #000;
    font-weight: bold;
  }
}

.sum {
  display: flex;
  flex-direction: column;
  // border: 1px solid #000;
  margin-right: auto;
  width: 100%;
  border: 1px solid #000;

  &__row {
    display: flex;
    align-items: center;
    width: 100%;
    height: $rowHeight;
    position: relative;
    border-bottom: 1px solid #000;

    &:last-child {
      border: none;
    }
  }

  &__type {
    width: 80%;
    height: 100%;
    border-right: 1px solid #000;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
  }
}

.kid {
  display: flex;
  align-items: center;
  height: $rowHeight;
  position: relative;
  border-top: 1px solid #000;

  &_ill {
    background-color: $secondaryColor;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  &__number {
    height: 100%;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #000;
  }

  &__name {
    height: 100%;
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #000;

    padding: 0 3px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
  }
}
