@import '../../../../assets/helpers/index.scss';

.sidebar {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100vh;
  background-color: $backgroundColor;
  padding: 80px 0px;
}
