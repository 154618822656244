@import '../../assets/helpers/colors.scss';

.table {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #fff;
    height: 40px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    span {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
