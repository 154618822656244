@import '../../assets/helpers/index.scss';

.sum {
  display: flex;
  flex-direction: column;

  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__header {
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__stars {
    display: flex;
    justify-content: center;
    position: relative;
  }
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  &__eye {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $backgroundColor;
    background-color: $backgroundColor;
    padding: 5px;
    cursor: pointer;

    & div {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    & span {
      display: flex;
      align-items: center;
      color: $secondaryColor;
    }

    &:hover span {
      color: $mainTextColor;
    }

    &_inactive {
      div {
        color: $secondaryColor;
      }
    }
  }
}

@keyframes slideIn {
  from {
    right: 150px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.hider {
  width: 100%;
  height: 100%;
  background-color: $backgroundColor;
  position: absolute;
  opacity: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $secondaryColor;
  border-radius: 5px;

  &_active {
    z-index: 2;
    opacity: 1;
    animation: slideIn 0.3s ease-in-out;
  }
}
