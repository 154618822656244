@import '../../../../assets/helpers/index.scss';

.item {
  position: relative;
  width: 150px;
  height: 120px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 90px;
    height: 55px;
    padding: 10px;
  }

  .title {
    font-size: 16px;
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  .triangle {
    position: absolute;
    bottom: -20px;
    border: 10px solid transparent;
    border-top: 10px solid $mainColor;
    left: calc(50% + 20px);
    display: none;
  }

  &_active {
    background-color: $mainColor;

    .title {
      color: #fff;
    }

    .triangle {
      display: block;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  &_disabled {
    cursor: default;
    background-color: $backgroundColor;
    border: 1px solid $disableColor;

    svg {
      path {
        fill: $disableColor;
      }
    }

    .title {
      color: $secondaryColor;
    }
  }
}
