@import '../../assets/helpers/index.scss';

.table {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    gap: 10px;
  }
}
