@import '../../../assets/helpers/index.scss';

.clock {
  display: flex;
  flex-direction: column;
  cursor: default;

  &__time {
    font-size: 24px;
    font-weight: bold;
  }

  &__date {
    font-size: 16px;
    font-weight: bold;
    color: $secondaryColor;
  }
}
