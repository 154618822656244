@import '../../../assets/helpers/colors.scss';

.row {
  min-height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid $disableColor;
  padding: 10px 0px;

  @media screen and (max-width: 600px) {
    overflow: hidden;
  }

  &__arrow {
    padding: 20px;
    cursor: pointer;
    transition: 0.3s;

    &_active {
      transform: rotate(180deg);
      color: $mainColor;
    }
  }

  &__tasks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 60px;
    margin: auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
    gap: 20px;
  }

  &__resetActiveTasks {
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: $mainColor;
    font-size: 16px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    display: none;
    cursor: pointer;

    &_active {
      display: flex;
    }
  }

  &__indicators {
    padding-right: 20px;
    display: flex;
    align-items: center;
    width: 155px;
    margin-left: 40px;

    @media screen and (max-width: 600px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
      margin-left: 20px;
    }

    & > *:not(:last-child) {
      margin-right: 5px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }
}

.inner {
  padding: 0px 20px;
  border: none;
  height: 0px;
  overflow: hidden;
  transition: 0.1s;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &_extended {
    padding: 20px;
    border: 1px solid $mainColor;
    border-right: none;
    border-left: none;
    height: auto;

    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
      gap: 10px;
    }
  }
}

.helper {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;

  &__button {
    color: $secondaryColor;
    cursor: pointer;
  }

  &__skeleton {
    span {
      height: 30px;
    }
  }

  &__text {
    width: 100%;
    padding: 20px;
    border: 1px solid $disableColor;
    border-radius: 5px;
    font-size: 16px;
    color: $secondaryColor;

    @media screen and (max-width: 600px) {
      padding: 10px;
      font-size: 12px;
    }
  }
}
